export default Object.freeze({
  GENESIS: {
    ADDRESS: 'did:com:1qlru3vdq7lajx4tpj0gdlm6lx5mgk09u7kpzxg',
    LABEL: 'Genesis',
    RANK: 1,
  },
  SALE_OUT: {
    ADDRESS: 'did:com:1peh9t8zpwtemtmy03ngqv6u6u6ezn5lznkqzz7',
    LABEL: 'Sale-out',
    RANK: 4,
  },
});
