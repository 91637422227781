import { cosmwasmApi } from '@/apis';
import { WALLET } from '@/constants';
import { msgBuilder, stringHandler } from '@/utils';

export default {
  async initMintToken({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchBalance', WALLET.GENESIS)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchBalance({ commit }, wallet) {
    const query = { balance: { address: wallet['ADDRESS'] } };
    const query_data = stringHandler.encodeToBase64(JSON.stringify(query));
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address: process.env.VUE_APP_CONTRACT_ADDRESS,
        query_data,
      });
      commit('setGenesisWallet', {
        balance: response.data.data.balance,
        address: wallet['ADDRESS'],
        label: wallet['LABEL'],
        rank: wallet['RANK'],
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  async transferTokens({ commit, dispatch, rootGetters }, amount) {
    if (!rootGetters['keplr/isConnected']) {
      await dispatch('keplr/connect', null, { root: true });
    }
    const textMsg = JSON.stringify({
      transfer: {
        amount,
        recipient: WALLET.SALE_OUT.ADDRESS,
      },
    });
    const wallet = rootGetters['keplr/wallet'];
    const message = msgBuilder.buildMsgExecuteContract({
      wallet,
      contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
      textMsg,
    });
    let isSuccessful = false;
    commit('setMinting', true);
    try {
      isSuccessful = await dispatch(
        'keplr/signAndBroadcastTransaction',
        [message],
        {
          root: true,
        },
      );
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setMinting', false);
    }
    return isSuccessful;
  },
};
