<template>
  <nav>
    <v-navigation-drawer v-model="drawer" app fixed disable-resize-watcher>
      <the-drawer />
    </v-navigation-drawer>
    <v-app-bar app color="white" flat>
      <v-row class="d-flex align-center">
        <v-col cols="2" md="5" class="d-flex justify-start">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer">
            <slot>
              <v-icon color="primary" size="35">{{
                drawer ? mdiWindowClose : mdiMenu
              }}</v-icon>
            </slot>
          </v-app-bar-nav-icon>
        </v-col>
        <v-col cols="3" md="2" class="d-flex justify-center">
          <router-link :to="{ name: ROUTES.NAME.HOME }">
            <v-img :src="imgSrc" :width="imgWidth" alt="Logo" />
          </router-link>
        </v-col>
        <v-col cols="7" md="5" class="d-flex justify-end">
          <the-connect-wallet-button />
        </v-col>
      </v-row>
      <the-connect-snack-bar />
    </v-app-bar>
  </nav>
</template>

<script>
import TheConnectSnackBar from './app-bar/TheConnectSnackBar.vue';
import TheConnectWalletButton from './app-bar/TheConnectWalletButton.vue';
import TheDrawer from './TheDrawer.vue';

import { mdiMenu, mdiWindowClose } from '@mdi/js';
import ROUTES from '@/constants/routes-constant';

export default {
  name: 'TheAppBar',
  components: {
    TheConnectSnackBar,
    TheConnectWalletButton,
    TheDrawer,
  },
  data() {
    return {
      ROUTES,
      mdiMenu,
      mdiWindowClose,
      drawer: false,
    };
  },
  computed: {
    imgSrc() {
      return require('@/assets/img/logo-small.png');
    },
    imgWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm':
          return '80';
        case 'md':
          return '120';
        case 'lg':
          return '180';
        case 'xl':
          return '200';
        default:
          return '60';
      }
    },
  },
};
</script>
