import axios from 'axios';

import { APIS } from '@/constants';

const cosmwasmApi = {
  /**
   *
   * @param {String} address
   * @param {String} query_data
   * @returns {AxiosPromise}
   */
  requestContractRawQuery({ address, query_data }) {
    return axios({
      url: `/contract/${address}/raw/${query_data}`,
      method: 'get',
      baseURL: APIS.COSWMWASM,
    });
  },
  /**
   *
   * @param {String} address
   * @param {String} query_data
   * @returns {AxiosPromise}
   */
  requestContractSmartQuery({ address, query_data }) {
    return axios({
      url: `/contract/${address}/smart/${query_data}`,
      method: 'get',
      baseURL: APIS.COSWMWASM,
    });
  },
};

export default cosmwasmApi;
