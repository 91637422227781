import { cosmwasmApi } from '@/apis';
import { stringHandler } from '@/utils';

export default {
  async initApp({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchTokenInfo')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchTokenInfo({ commit }) {
    const address = process.env.VUE_APP_CONTRACT_ADDRESS;
    const query = stringHandler.encodeToBase64('{"token_info":{}}');
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address,
        query_data: query,
      });
      commit('setTokenInfo', response.data.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
