export default Object.freeze({
  PATH: {
    HOME: '/home',
    MINT_TOKENS: '/mint',
    NOT_FOUND: '/:notFound(.*)',
    ROOT: '/',
  },
  NAME: {
    HOME: 'home',
    MINT_TOKENS: 'mint',
  },
  TITLE: {
    HOME: 'Home',
    MINT_TOKENS: 'Mint Tokens',
  },
});
