import { SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import { assertIsDeliverTxSuccess } from '@cosmjs/stargate';

import { CHAIN } from '@/constants';

export default {
  async connect({ commit, dispatch }) {
    commit('reset');
    if (!window.keplr) {
      commit('setHasKeplr', false);
      commit('setError', 'Please install Keplr extension');
      return;
    }
    if (!process.env.VUE_APP_CHAIN_ID) {
      commit('setError', 'Failed to detect the chain');
      return;
    }
    commit('setLoading', true);
    await dispatch('suggestChain');
    await dispatch('getAccounts');
    dispatch('subscribeKeyStoreChange');
    commit('setLoading', false);
  },
  async suggestChain({ commit }) {
    try {
      await window.keplr.experimentalSuggestChain({
        chainId: process.env.VUE_APP_CHAIN_ID,
        chainName: process.env.VUE_APP_CHAIN_NAME,
        rpc: process.env.VUE_APP_CHAIN_RPC,
        rest: process.env.VUE_APP_CHAIN_LCD,
        bip44: {
          coinType: CHAIN.COIN_TYPE,
        },
        bech32Config: {
          bech32PrefixAccAddr: CHAIN.PREFIXES.ACCOUNT.ADDRESS,
          bech32PrefixAccPub: CHAIN.PREFIXES.ACCOUNT.KEY,
          bech32PrefixValAddr: CHAIN.PREFIXES.VALIDATOR.OPERATOR.ADDRESS,
          bech32PrefixValPub: CHAIN.PREFIXES.VALIDATOR.OPERATOR.KEY,
          bech32PrefixConsAddr: CHAIN.PREFIXES.VALIDATOR.CONSENSUS.ADDRESS,
          bech32PrefixConsPub: CHAIN.PREFIXES.VALIDATOR.CONSENSUS.KEY,
        },
        currencies: [
          {
            coinDenom: CHAIN.TOKEN.SYMBOL,
            coinMinimalDenom: CHAIN.TOKEN.DENOM,
            coinDecimals: CHAIN.TOKEN.EXPONENT,
          },
          {
            coinDenom: CHAIN.STABLE_COIN.SYMBOL,
            coinMinimalDenom: CHAIN.STABLE_COIN.DENOM,
            coinDecimals: CHAIN.STABLE_COIN.EXPONENT,
          },
        ],
        feeCurrencies: [
          {
            coinDenom: CHAIN.STABLE_COIN.SYMBOL,
            coinMinimalDenom: CHAIN.STABLE_COIN.DENOM,
            coinDecimals: CHAIN.STABLE_COIN.EXPONENT,
            gasPriceStep: {
              low: CHAIN.GAS_PRICE_STEP.LOW,
              average: CHAIN.GAS_PRICE_STEP.AVERAGE,
              high: CHAIN.GAS_PRICE_STEP.HIGH,
            },
          },
          {
            coinDenom: CHAIN.TOKEN.SYMBOL,
            coinMinimalDenom: CHAIN.TOKEN.DENOM,
            coinDecimals: CHAIN.TOKEN.EXPONENT,
            gasPriceStep: {
              low: CHAIN.GAS_PRICE_STEP.LOW,
              average: CHAIN.GAS_PRICE_STEP.AVERAGE,
              high: CHAIN.GAS_PRICE_STEP.HIGH,
            },
          },
        ],
        stakeCurrency: {
          coinDenom: CHAIN.TOKEN.SYMBOL,
          coinMinimalDenom: CHAIN.TOKEN.DENOM,
          coinDecimals: CHAIN.TOKEN.EXPONENT,
        },
      });
    } catch (error) {
      commit('setError', 'Failed to suggest the chain');
    }
  },
  async getAccounts({ commit }) {
    try {
      await window.keplr.enable(process.env.VUE_APP_CHAIN_ID);
      const offlineSigner = window.keplr.getOfflineSigner(
        process.env.VUE_APP_CHAIN_ID,
      );
      const accounts = await offlineSigner.getAccounts();
      commit('setAccounts', accounts);
      commit('setConnected', true);
    } catch (error) {
      commit(
        'setError',
        'No accounts found. Use the Keplr extension to create it or import it.',
      );
    }
  },
  subscribeKeyStoreChange({ commit, dispatch }) {
    window.addEventListener('keplr_keystorechange', async () => {
      commit('setLoading', true);
      commit('setAccounts', []);
      commit('setConnected', false);
      await dispatch('getAccounts');
      commit('setLoading', false);
    });
  },
  disconnect({ commit }) {
    commit('reset');
  },
  resetError({ commit }) {
    commit('setError', undefined);
  },
  async signAndBroadcastTransaction({ commit, getters }, messages) {
    try {
      const wallet = getters['wallet'];
      if (!wallet) {
        return;
      }
      const offlineSigner = await window.keplr.getOfflineSignerAuto(
        process.env.VUE_APP_CHAIN_ID,
      );
      const client = await SigningCosmWasmClient.connectWithSigner(
        process.env.VUE_APP_CHAIN_RPC,
        offlineSigner,
      );
      const fee = calculateFee(messages.length);
      const result = await client.signAndBroadcast(wallet, messages, fee);
      assertIsDeliverTxSuccess(result);
      return true;
    } catch (error) {
      commit('setError', error);
      return false;
    }
  },
};

const calculateFee = (messages) => ({
  amount: [
    {
      denom: CHAIN.STABLE_COIN.DENOM,
      amount: (CHAIN.FEE_AMOUNT * messages).toString(),
    },
  ],
  gas: (CHAIN.GAS_AMOUNT * messages).toString(),
});
