import { v4 as uuidv4 } from 'uuid';

const wallets = [
  {
    address: uuidv4(),
    balance: '250000000000',
    isMock: true,
    label: 'Riv DEX Liquidity',
    rank: 2,
  },
  {
    address: uuidv4(),
    balance: '250000000000',
    isMock: true,
    label: 'USDC DEX Liquidity',
    rank: 3,
  },
];

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  wallets: (state) => state.wallets.concat(wallets),
};
