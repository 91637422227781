import { homeApi } from '@/apis';

export default {
  async initHomeAccounts({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchAccounts')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchAccounts({ commit }) {
    try {
      const response = await homeApi.requestAccounts();
      commit('setAccounts', response.data.accounts);
    } catch (error) {
      commit('setError', error);
    }
  },
};
