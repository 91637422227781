const tokensHandler = {
  formatBalance({
    amount,
    decimals = 0,
    maximumFractionDigits = 0,
    minimumFractionDigits = 0,
  } = {}) {
    const balance = parseInt(amount) / Math.pow(10, decimals);
    return new Intl.NumberFormat(undefined, {
      style: 'decimal',
      maximumFractionDigits,
      minimumFractionDigits,
    }).format(balance);
  },
};

export default tokensHandler;
