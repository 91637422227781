const PREFIXES = Object.freeze({
  ACCOUNT: {
    ADDRESS: 'did:com:',
    KEY: 'did:com:pub',
  },
  VALIDATOR: {
    CONSENSUS: {
      ADDRESS: 'did:com:valcons',
      KEY: 'did:com:valconspub',
    },
    OPERATOR: {
      ADDRESS: 'did:com:valoper',
      KEY: 'did:com:valoperpub',
    },
  },
});

const STABLE_COIN = Object.freeze({
  NAME: 'commercio cash credits',
  SYMBOL: 'CCC',
  DENOM: 'uccc',
  EXPONENT: 6,
  VALUE: {
    AMOUNT: '1.00',
    SYMBOL: '€',
  },
});

const TOKEN = Object.freeze({
  NAME: 'commercio',
  SYMBOL: 'COM',
  DENOM: 'ucommercio',
  EXPONENT: 6,
  STAKEABLE: true,
});

const TOKEN_KEY = Object.freeze({
  TOKEN_1: 'token1',
  TOKEN_2: 'token2',
});

const TOKEN_TYPE = Object.freeze({
  CW20: 'cw20',
  NATIVE: 'native',
});

const COIN_TYPE = 118;
const FEE_AMOUNT = 10000;
const GAS_AMOUNT = 1000000;
const GAS_PRICE_STEP = Object.freeze({
  AVERAGE: 0.125,
  HIGH: 0.15,
  LOW: 0.1,
});

export default Object.freeze({
  COIN_TYPE,
  FEE_AMOUNT,
  GAS_AMOUNT,
  GAS_PRICE_STEP,
  PREFIXES,
  STABLE_COIN,
  TOKEN,
  TOKEN_KEY,
  TOKEN_TYPE,
});
