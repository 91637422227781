<template>
  <v-card outlined>
    <v-card-title>
      <span class="font-weight-bold" v-text="title" />
    </v-card-title>
    <v-card-text>
      <span :class="style" v-text="datum" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BaseBoxCard',
  props: ['title', 'datum', 'mock'],
  computed: {
    style() {
      return this.mock
        ? 'blue-grey--text text--lighten-2'
        : 'grey--text text--darken-4';
    },
  },
};
</script>
