import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import BaseBoxCard from '@/components/BaseBoxCard.vue';
import BaseErrorMessage from '@/components/BaseErrorMessage.vue';
import TheFramedWindow from '@/components/TheFramedWindow.vue';

Vue.component('base-box-card', BaseBoxCard);
Vue.component('base-error-message', BaseErrorMessage);
Vue.component('the-framed-window', TheFramedWindow);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
