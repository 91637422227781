import { homeApi } from '@/apis';

export default {
  async initHomeHistory({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchHistory')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchHistory({ commit }) {
    try {
      const response = await homeApi.requestHistory();
      commit('setHistory', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
