import { v4 as uuidv4 } from 'uuid';

const mockData = (title, datum) => {
  const item = { id: uuidv4(), title, datum };
  return item;
};

const homeApi = {
  requestAccounts() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            accounts: [
              mockData('Sale', '$250.000'),
              mockData('Vault', '$250.000'),
              mockData('Cash Equivalents', '$250.000'),
              mockData('Interests', '$250.000'),
            ],
          },
        });
      }, 100);
    });
  },
  requestHistory() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            title: 'Genesis',
            action: 'Mint Riv Coins',
            amount: '$1000 (+)',
            timestamp: '2023-05-15 00:00:00',
            user: 'User name',
          },
        });
      }, 100);
    });
  },
  requestPrices() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            prices: [
              mockData('Presale Price', '1€'),
              mockData('DEX Price', ''),
              mockData('Reserve Price', ''),
              mockData('Reference Price', ''),
            ],
          },
        });
      }, 100);
    });
  },
};

export default homeApi;
