<template>
  <v-sheet>
    <v-alert border="left" outlined type="error">
      <template>
        <div>Sorry, at this point in time the data is not available.</div>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>More info</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <span class="font-weight-bold">message</span>
                <span class="ml-2" v-text="message" />
              </div>
              <div>
                <span class="font-weight-bold">code</span>
                <span class="ml-2" v-text="code" />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-alert>
  </v-sheet>
</template>

<script>
import { errorHandler } from '@/utils';

export default {
  name: 'BaseErrorMessage',
  props: {
    error: {
      type: [Error, Object],
      required: true,
      note: 'The error to display',
    },
  },
  computed: {
    message() {
      return this.processedError &&
        this.processedError.data &&
        this.processedError.data.message
        ? this.processedError.data.message
        : 'No message';
    },
    code() {
      return this.processedError && this.processedError.status
        ? this.processedError.status
        : 'No code';
    },
    processedError() {
      return errorHandler.process({
        error: this.error,
        msgUnansweredRequest:
          'The request was made but no response was received',
        msgTriggeredAnError:
          'Something happened in setting up the request that triggered an error',
      });
    },
  },
};
</script>
