const stringHandler = {
  encodeToBase64(data) {
    return btoa(data);
  },
  decodeFromBase64(data) {
    return atob(data);
  },
};

export default stringHandler;
