import { cosmwasmApi } from '@/apis';
import { WALLET } from '@/constants';
import { stringHandler } from '@/utils';

export default {
  async initHomeWallets({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchBalance', WALLET.GENESIS),
      dispatch('fetchBalance', WALLET.SALE_OUT),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchBalance({ commit }, wallet) {
    const query = { balance: { address: wallet['ADDRESS'] } };
    const query_data = stringHandler.encodeToBase64(JSON.stringify(query));
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address: process.env.VUE_APP_CONTRACT_ADDRESS,
        query_data,
      });
      commit('addWallet', {
        address: wallet['ADDRESS'],
        balance: response.data.data.balance,
        isMock: false,
        label: wallet['LABEL'],
        rank: wallet['RANK'],
      });
    } catch (error) {
      commit('setError', error);
    }
  },
};
