<template>
  <v-list-item-group>
    <base-drawer-menu-item
      :icon="mdiTreasureChest"
      title="Treasury"
      :to="{ path: ROUTES.PATH.ROOT }"
    />
    <base-drawer-menu-item
      :icon="mdiAccountMultiple"
      title="Users"
      :to="{ path: ROUTES.PATH.ROOT }"
    />
    <base-drawer-menu-item
      :icon="mdiChartPie"
      title="Stats"
      :to="{ path: ROUTES.PATH.ROOT }"
    />
  </v-list-item-group>
</template>

<script>
import BaseDrawerMenuItem from './BaseDrawerMenuItem.vue';

import { ROUTES } from '@/constants';
import { mdiAccountMultiple, mdiChartPie, mdiTreasureChest } from '@mdi/js';

export default {
  name: 'TheDrawerRoutes',
  components: { BaseDrawerMenuItem },
  data() {
    return {
      ROUTES,
      mdiAccountMultiple,
      mdiChartPie,
      mdiTreasureChest,
    };
  },
};
</script>
