import Vue from 'vue';
import VueRouter from 'vue-router';

import { ROUTES } from '@/constants';
import homeRoute from './routes/home-route';
import mintTokensRoute from './routes/mint-tokens-route';
import notFoundRoute from './routes/not-found-route';

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTES.PATH.ROOT,
    redirect: () => ROUTES.PATH.HOME,
  },
];

routes.push(homeRoute, mintTokensRoute, notFoundRoute);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

export function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}
