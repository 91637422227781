import Vue from 'vue';
import Vuex from 'vuex';

import application from './application';
import home from './home';
import homeAccounts from './home/accounts';
import homeHistory from './home/history';
import homeRivcoin from './home/rivcoin';
import homeWallets from './home/wallets';
import keplr from './keplr';
import mintTokens from './mint-tokens';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    // root
    application,
    // namespaced
    home,
    homeAccounts,
    homeHistory,
    homeRivcoin,
    homeWallets,
    keplr,
    mintTokens,
  },
});
